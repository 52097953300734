    // Core
    @import "core";
    // Fonts
    @import url('https://fonts.googleapis.com/css?family=Heebo:400,700&display=swap');
    // Widgets
    @import "widgets";
    // Components
    @import "components";
    // Layout Components
    @import "layout-components";
    // Layout Blueprints
    @import "layout-blueprints";
    .fade-enter {
        opacity: 0.01;
    }
    
    .fade-enter.fade-enter-active {
        opacity: 1;
        transition: opacity 500ms ease-in;
    }
    
    .fade-exit {
        opacity: 1;
    }
    
    .fade-exit.fade-exit-active {
        opacity: 0.01;
        transition: opacity 300ms ease-in;
    }
    
    // .app-content {
    //     padding-top: 0px !important;
    // }
    .app-sidebar-logo--icon {
        img {
            width: 50px;
            animation: none;
        }
    }
    
    .app-sidebar--light .app-sidebar-logo--icon {
        background: #fafafa;
        box-shadow: none;
        width: 50px;
        height: 50px;
        margin-right: 5px;
    }
    
    .app-page-title {
        background-color: #f1f1f1;
    }
    
    .app-sidebar--light .app-sidebar--userbox {
        background-color: #ffffff;
        margin-bottom: 50px;
        margin-top: 0;
    }
    
    .app-page-title--iconbox {
        box-shadow: none;
    }
    
    body .MuiCard-root {
        border-radius: 0;
        border: none;
    }
    
    .app-content {
        background-color: #f1f1f1;
    }
    
    .app-sidebar--light .sidebar-navigation ul li>a:hover,
    .app-sidebar--light .sidebar-navigation ul li>a.active {
        color: #000000;
        background: #f1f1f1
    }
    
    .app-sidebar--light .app-sidebar--header::after {
        background: #ffffff;
    }
    
    .app-sidebar--userbox {
        display: block;
        text-align: left;
        padding: 0;
    }
    
    // .app-sidebar--light .sidebar-navigation ul li>a .sidebar-icon>*,
    // .app-sidebar--light .sidebar-navigation ul li>a:hover .sidebar-icon>*,
    // .app-sidebar--light .sidebar-navigation ul li>a.active .sidebar-icon>* {
    //     color: "#E52F42"
    // }
    .text-primary {
        color: black !important;
    }
    
    .app-header,
    .app-footer {
        background-color: #f1f1f1,
    }
    
    .app-footer:hover,
    .app-header:hover {
        background-color: #f1f1f1 !important,
    }