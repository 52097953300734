@import url('https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/*General*/

body {
    font-family: 'Public Sans', sans-serif;
}

.app-wrapper {
    background: #fff;
}

.text-first {
    color: #fff !important;
}

a.text-first:hover {
    color: #fff !important;
    font-weight: 600;
}


/*Button head*/

.app-page-title button {
    display: none;
}


/*Card-general*/

body .MuiCard-root {
    border-radius: 0.2rem;
}

.MuiPaper-elevation1 {
    box-shadow: none !important;
}

.score-status {
    margin: 10px 0 10px 5px;
}


/*Card-views*/

.category-view .category-card {
    background: #666;
    color: #fff;
}

.category-view .category-card .category-img-wrapper {
    padding: 15px 0;
}

.category-view .bg-second {
    background-color: #666 !important;
}

.category-view .MuiChip-root {
    background: #c1c1c1;
    border-radius: 0.2rem;
    margin: 0 2px 5px 0;
}

.dashboard-view .d-60,
.category-view .d-60 {
    height: 40px !important;
    line-height: 40px !important;
    // width: auto !important;
    padding: 0 15px;
}

.insights-card span {
    font-size: 1.2rem;
    font-weight: 600;
}

.actionbox-card p {
    color: #fff;
}

.app-sidebar--light .app-sidebar-logo--icon {
    background: #ffffff;
    width: 150px;
    // height: 50px;
    img {
        width: 100%;
    }
}

.app-page-title--iconbox {
    background: none;
    .MuiSvgIcon-root {
        font-size: 4rem;
        color: #E52F42 !important;
    }
}

.app-page-title--heading,
.app-page-title--description {
    color: black;
    // margin: 0.5rem 0 0;
    // font-size: 1.1rem;
    // opacity: .6;
    // font-weight: normal;
}

.sidebar-navigation ul li>a,
.app-page-title--heading h1 {
    font-weight: 600;
}

.app-page-title {
    padding-top: 0;
    padding-bottom: 0;
}

.card-header-alt {
    margin-bottom: 30px;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-weight: 600;
}

.insights-card .insigt-item p {
    opacity: 0.6;
    font-size: 0.9rem;
}

.category-view .MuiChip-root {
    font-size: 0.9rem;
}

.app-sidebar--light .hamburger-inner,
.app-sidebar--light .hamburger-inner:after,
.app-sidebar--light .hamburger-inner:before,
.app-header .hamburger-inner,
.app-header .hamburger-inner:after,
.app-header .hamburger-inner:before {
    background: #000000 !important;
}